
export const getQueryParam = (name: 'instance' | 'compId' | 'viewMode' | 'origCompId') => {
    const queryParamValue = new URLSearchParams(window.location.search).get(name);
    if (!queryParamValue) {
        throw new Error(`client:No ${name} query param`);
    }
    return queryParamValue;
}

export const isURL = (url: string) => {
    try {
        new URL(url);
        return true;
    } catch {
        return false;
    }
}

export const isMapsUrl = (url: string) => {
    if (!isURL(url)) {
        return false;
    }

    const fullURLPattern = /google\.[a-z]{2,3}\/maps\/place/;
    if (fullURLPattern.test(url)) {
        return true;
    }

    const shortURLPattern = /maps\.app\.goo\.gl/;
    if (shortURLPattern.test(url)) {
        return true;
    }

    return false;
}

