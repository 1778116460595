export enum Collections {
    instances = 'instances',
    settings = 'settings',
    places = 'places',
    reviews = 'reviews',
    feedbacks = 'feedbacks',
}

export const SUPPORT_EMAIL = 'support@beaver.codes';
export const REVIEW_LINK = 'https://www.wix.com/app-market/add-review/a64a279e-ac90-4dcd-9935-29b40186026f'
export const MAILTO = `mailto:${SUPPORT_EMAIL}?subject=Google%20Review%20List`;
/* eslint-disable max-len */
// tslint:disable-next-line:max-len
export const MAILTO_BACKFILL = `mailto:${SUPPORT_EMAIL}?subject=Order%20Backfill&body=Hi%20Beaver%2C%0APlease%20backfill%20my%20reviews.%0A%0ACompId%3A%20xxx`;
// tslint:disable-next-line:max-len
export const MAILTO_LOCATION = `mailto:${SUPPORT_EMAIL}?subject=Wix%20Google%20Review%20List%20-%20Find%20Place&body=Google%20Maps%20Link%3A%20%3C...please%20fill%3E%0AWebsite%20URL%3A%20%3C...please%20fill%3E`
/* eslint-enable max-len */

export const MAIL_APP_NAME = 'Google Reviews List';
export const MAIL_APP_TAG = 'app-google-reviews-list';


const APP_ID = 'a64a279e-ac90-4dcd-9935-29b40186026f';
export const UPGRADE_LINK = `https://www.wix.com/apps/upgrade/${APP_ID}?appInstanceId=`

export enum PubSubTopics {
    backfill = 'backfill',
    update = 'update',
}

export enum EmailSubject {
    Feedback = "Share Your Thoughts - Help Us Better Serve You!",
    Onboard = "Set Up and Start Showcasing Your Google Reviews!",
    RemiderOnboard = "Reminder: Set Up and Start Showcasing Your Google Reviews!",
    Import = "Reviews Import",
    WidgetLaunch = "Introducing the All-New Google Reviews Widget",
}

export enum EmailTemplate {
    FeedbackReq = "g-feedback-req",
    ReviewsOnboard = "g-reviews-onboard",
    ReviewsImport = "reviews_import",
    WidgetLaunch = "widgetlaunch",
}

export const DEFAUTL_SUBJECT: Record<EmailTemplate, EmailSubject> = {
    [EmailTemplate.FeedbackReq]: EmailSubject.Feedback,
    [EmailTemplate.ReviewsOnboard]: EmailSubject.Onboard,
    [EmailTemplate.ReviewsImport]: EmailSubject.Import,
    [EmailTemplate.WidgetLaunch]: EmailSubject.WidgetLaunch,
};

