import React, { useEffect, useMemo } from 'react'
import { DEFAULT_REVIEWS, EMPTY_REVIEWS, TRIAL_ENDED_REVEIWS } from '../utils/defaults'
import { useFirestore } from 'reactfire';
import { getQueryParam } from '../utils/url';
import { Settings } from '../models/shared/Settings';
import { QueryConstraint, collection, doc, limit, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { Collections } from '../utils/shared/constants';
import { Review } from '../models/shared/Review';
import { publishComponent } from '../utils/api';
import { useInstanceData } from '../hooks/useInstanceData';
import ReviewBox from './ReviewBox';

const BASE_TIME = 100;
const TARGET_COUNT = 20;


export default function Display() {
    const db = useFirestore();
    const componentId = getQueryParam('compId');
    const viewMode = getQueryParam('viewMode');
    const [settings, setSettings] = React.useState<null | Settings>(null);
    const [reviews, setReviews] = React.useState<Review[]>([]);
    const { trialEnded, loaded, instanceData } = useInstanceData();

    const isEditor = viewMode === 'editor';
    const displaySettings = isEditor ? settings?.editor : settings?.viewer;
    useEffect(() => {
        if (isEditor) {
            (window as any).Wix.addEventListener('SITE_PUBLISHED', () => {
                publishComponent(componentId);
            })
        }
    }, [componentId, isEditor])

    useEffect(() => {
        if (loaded && trialEnded && !instanceData.paidInstance) {
            if (isEditor) {
                setReviews(TRIAL_ENDED_REVEIWS);
            }
            return;
        }
        if (componentId && db) {
            return onSnapshot(doc(db, Collections.settings, componentId), snapshot => {
                if (snapshot.exists()) {
                    setSettings(snapshot.data() as Settings);
                } else {
                    console.warn('No settings found for component', componentId);
                    if (isEditor) {
                        const finalReviews = DEFAULT_REVIEWS.map(review => ({
                            ...review,
                            text: '[EXAMPLE REVIEW] ' + review.text,
                        }))
                        setReviews(finalReviews);
                    }
                }
            })
        }
    }, [componentId, db, trialEnded, loaded, isEditor, instanceData.paidInstance])

    useEffect(() => {
        if (!displaySettings?.placeId) {
            return;
        }
        const _col = collection(db, Collections.places, displaySettings.placeId, Collections.reviews);

        const minStarCount = displaySettings?.minStarCount || 1;
        const filterByStarCount = minStarCount > 1;

        const constraints: QueryConstraint[] = []
        if (filterByStarCount) {
            const validStarCount = [];
            for (let i = minStarCount; i <= 5; i++) {
                validStarCount.push(i);
            }
            constraints.push(where('rating', 'in', validStarCount));
        }

        if (displaySettings.usePinned) {
            constraints.push(where('pinned', '==', true));
        }

        constraints.push(orderBy('time', 'desc'));

        const needsPostFilter = displaySettings.textReviewsOnly || displaySettings.forcePostFilter;
        if (!needsPostFilter) {
            constraints.push(limit(TARGET_COUNT));
        }

        const q = query(_col, ...constraints);
        return onSnapshot(q, snapshot => {
            const newReviews = snapshot.docs.map(doc => doc.data() as Review);
            if (newReviews.length === 0 && isEditor) {
                setReviews(EMPTY_REVIEWS);
            } else {
                setReviews(newReviews);
            }
        })
    }, [db, displaySettings?.minStarCount, displaySettings?.placeId, displaySettings?.forcePostFilter, displaySettings?.usePinned, displaySettings?.textReviewsOnly, isEditor])


    let processedReviews = useMemo(() => {
        let finalReviews = [...reviews].filter(review => !review.hide);

        if (displaySettings?.minTime) {
            const minTime = displaySettings.minTime;
            finalReviews = finalReviews.filter(review => review.time > minTime);
        }
        if (displaySettings?.textReviewsOnly) {
            finalReviews = finalReviews.filter(review => review.text?.trim().length > 0);
        }

        while (finalReviews.length > 0 && finalReviews.length < TARGET_COUNT) {
            finalReviews = finalReviews.concat(finalReviews);
        }
        if (displaySettings?.randomOrder) {
            finalReviews = finalReviews.sort(() => Math.random() - 0.5);
        }

        finalReviews = finalReviews.slice(0, TARGET_COUNT);

        finalReviews = finalReviews.reverse();

        return finalReviews;
    }, [displaySettings?.minTime, displaySettings?.textReviewsOnly, displaySettings?.randomOrder, reviews]);

    const speed = displaySettings?.speed || 1;
    const count = processedReviews.length;
    const animationTime = BASE_TIME / Math.abs(speed);
    const offset = animationTime / count;
    const halfTime = animationTime / 2;

    return (
        <div className={`center display ${displaySettings?.className || ''}`}
            data-settings-id={componentId}
            data-instance-id={settings?.instanceId}
            data-place-id={displaySettings?.placeId}
        >
            <div className='center review-container'>
                {processedReviews.map((review, index) => {
                    return <ReviewBox
                        key={index}
                        index={index}
                        review={review}
                        animation={`${animationTime}s -${offset * (index + 3) + halfTime}s linear infinite ${speed < 0 ? 'move-right' : 'move-left'}`}
                        displaySettings={displaySettings}
                    />
                })}

            </div>
        </div >
    )


}
