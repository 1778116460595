import React from 'react'
import { MAILTO_LOCATION, SUPPORT_EMAIL } from '../utils/shared/constants';
import { Checkbox } from '@wix/design-system';

interface Props {
    searchCount: number
}

const GOOGLE_MAPS_URL = 'https://www.google.com/maps';
const MAILTO = <a href={MAILTO_LOCATION}>{SUPPORT_EMAIL}</a>

export default function LocationSearchHelp(props: Props) {
    const [open, setOpen] = React.useState(false);
    const [hasAddress, setHasAddress] = React.useState(true);

    // const buttonText
    const fontSize = Math.min(0.5 + 0.2 * props.searchCount, 3) + 'rem';

    return (
        <div className={`mt-2 ${open && 'border'}`}>
            <button className={`btn center ${open ? 'w-100' : 'border'}`} onClick={() => setOpen(!open)} >
                <span className='text-primary me-1' style={{ fontSize }}>trouble</span> finding your place<span className='text-primary ms-1' style={{ fontSize }}>?</span>
            </button>
            {open && <div className='p-2'>
                <div className="form-check">
                    <Checkbox checked={hasAddress} onChange={event => setHasAddress(event.target.checked)}>

                        I have public address on <a href={GOOGLE_MAPS_URL} target='_blank' rel="noreferrer" >Google Maps</a>
                    </Checkbox>

                    {hasAddress
                        ? <p className='text-justify'>Use the same place name as on Google. You can also try to add parts of the address such as street, city or country to your name.</p>
                        : <p className='text-justify'>Places without address can <span className='bold'>not</span> be found using name. Paste in the url to your profile on Google Maps to request import.</p>}
                    <p className='text-justify text-muted'>Always please feel free to reach out and we will gladly help you - {MAILTO}</p>
                </div>
            </div>}
        </div >
    )
}
