import { Review } from "../models/shared/Review";

const time = new Date().getTime() / 1000;

export const DEFAULT_REVIEWS: Review[] = [
    {
        "author_name": "Kiera Chen",
        "author_url": "https://www.google.com/maps/contrib/106146219703091297594/reviews",
        "profile_photo_url": "https://lh3.googleusercontent.com/a/AGNmyxYrfERvTirh4wgkGw0j3aqI-obcTZnH-pjBgLpa=s128-c0x00000000-cc-rp-mo",
        "rating": 5,
        "relative_time_description": "in the last week",
        "text": "I had an amazing experience using this service! The team was incredibly helpful and professional. 🤩👍",
        "time": time,
        "translated": false
    },
    {
        "author_name": "Marcus Svensson",
        "author_url": "https://www.google.com/maps/contrib/115325982593794571349/reviews",
        "profile_photo_url": "https://lh3.googleusercontent.com/a-/ACB-R5RqiMJQ40ZgydiaLIVOtoZ88wGFIlU9mx6t4fcbEUw=w60-h60-p-rp-mo-ba3-br100",
        "rating": 5,
        "relative_time_description": "in the last week",
        "text": "I'm blown away by how great this service is! The staff was friendly, attentive, and knowledgeable.",
        "time": time,
        "translated": false
    },
    {
        "author_name": "Tariq Williams",
        "author_url": "https://www.google.com/maps/contrib/111450651669317946231/reviews",
        "language": "sv",
        "original_language": "sv",
        "profile_photo_url": "https://lh3.googleusercontent.com/a/AGNmyxZAnpy1sRJu-hMIXhMaiWXSfVB8T9Bx4kLuDL38=s128-c0x00000000-cc-rp-mo",
        "rating": 5,
        "relative_time_description": "a week ago",
        "text": "Incredible experience! 5 stars!",
        "time": time,
        "translated": false
    },
    {
        "author_name": "Jaxon Kim",
        "author_url": "https://www.google.com/maps/contrib/103363121562977209468/reviews",
        "profile_photo_url": "https://lh3.googleusercontent.com/a/AGNmyxZErmvzOHxF9PVsSk45cStErf_jZsOXJJpXBUGG=s128-c0x00000000-cc-rp-mo",
        "rating": 5,
        "relative_time_description": "a week ago",
        "text": "Amazing service! Highly recommend! 🤩👍",
        "time": time,
        "translated": false
    },
    {
        "author_name": "Simone Lee",
        "author_url": "https://www.google.com/maps/contrib/103442517822643833504/reviews",
        "language": "en",
        "original_language": "en",
        "profile_photo_url": "https://lh3.googleusercontent.com/a/AGNmyxZpM0N2pAG1OdQd57C5VN63Xu2NHSxfzQB_T_Nt=s128-c0x00000000-cc-rp-mo",
        "rating": 4,
        "relative_time_description": "a week ago",
        "text": "There were a few minor issues that I encountered, but overall I was satisfied with the results.",
        "time": time,
        "translated": false
    }
] as any[];

const trialEndedName = '<Trial Has Ended>'
const trialEndedText = 'Please upgrade to a paid plan from settings to continue using this widget.'

export const TRIAL_ENDED_REVEIWS: Review[] = [

    {
        "author_name": trialEndedName,
        "author_url": "https://www.google.com/maps/contrib/111450651669317946231/reviews",
        "language": "sv",
        "original_language": "sv",
        "profile_photo_url": "https://lh3.googleusercontent.com/a/AGNmyxZAnpy1sRJu-hMIXhMaiWXSfVB8T9Bx4kLuDL38=s128-c0x00000000-cc-rp-mo",
        "rating": 5,
        "relative_time_description": "a week ago",
        "text": trialEndedText,
        "time": time,
        "translated": false
    },

] as any[];

const emptyName = '<No reviews found>'
const emptyText = 'Are you sure you have choosen the right business?'

export const EMPTY_REVIEWS: Review[] = [

    {
        "author_name": emptyName,
        "author_url": "https://www.google.com/maps/contrib/111450651669317946231/reviews",
        "language": "sv",
        "original_language": "sv",
        "profile_photo_url": "https://lh3.googleusercontent.com/a/AGNmyxZAnpy1sRJu-hMIXhMaiWXSfVB8T9Bx4kLuDL38=s128-c0x00000000-cc-rp-mo",
        "rating": 5,
        "relative_time_description": "a week ago",
        "text": emptyText,
        "time": time,
        "translated": false
    },

] as any[];
