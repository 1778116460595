import React from 'react'
import { Star, StarEmpty } from './SVG';


interface Props {
    value: number
}

export default function Rating({ value }: Props) {
    const images = [1, 2, 3, 4, 5].map((_, index) => <React.Fragment key={index}>{index - 2 < value ? Star : StarEmpty}</React.Fragment>)


    return (
        <div className='rating'>
            {images}
        </div>
    )
}
