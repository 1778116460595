import React from 'react'
import { DateFormat } from '../models/shared/Settings';

interface Props {
    time: number
    format: DateFormat
}

const formatTime = (seconds: number, format: DateFormat) => {
    const date = new Date(seconds * 1000);

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    if (format === 'DD/MM/YYYY') {
        return `${day}/${month}/${year}`;
    } else {
        return `${month}/${day}/${year}`;
    }
}

export default function Time({ time, format }: Props) {
    if (format === 'Hide') {
        return null;
    }
    return (
        <div className='time'>{formatTime(time, format)}</div>
    )
}
