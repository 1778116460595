import React from 'react'
import { Review } from '../models/shared/Review';
import { DateFormat, DisplaySettings } from '../models/shared/Settings';
import Rating from './Rating';
import Time from './Time';

interface Props {
    index: number;
    animation: string;
    review: Review;
    displaySettings?: DisplaySettings;

}

const MAX_CHARACTERS = 105;
const BASE_HEIGHT = 150;
const SMALL_HEIGHT = 120;

function ReviewBox(props: Props) {
    const { index, review, displaySettings } = props

    const dateFormat: DateFormat = displaySettings?.dateFormat || 'DD/MM/YYYY';
    const size = displaySettings?.size || 1;
    const baseHeight = window.innerWidth < 768 ? SMALL_HEIGHT : BASE_HEIGHT;
    const height = Math.round(baseHeight * size);

    let shortenedText = review.text;
    const textLength = review.text?.length || 0;
    if (size === 1 && textLength > MAX_CHARACTERS) {
        shortenedText = review.text.slice(0, MAX_CHARACTERS) + ' ...';
    }

    let authorName = review.author_name;
    if (displaySettings?.nameFormat === 'first-name-only') {
        const names = review.author_name.split(' ');
        for (let i = 1; i < names.length; i++) {
            names[i] = names[i][0] + '.';
        }
        authorName = names.join(' ');
    }

    let reviewLink = displaySettings?.placeUrl
    if (review.author_url && !displaySettings?.manual) {
        reviewLink = review.author_url.replace('/reviews', '/place/' + displaySettings?.placeId);
    }

    const renderContent = () => {
        return <>
            <div className='author'>
                <img src={review.profile_photo_url} alt={review.author_name} />
                <div className='name' style={{
                    fontFamily: displaySettings?.nameFont || '',
                    fontSize: displaySettings?.nameSize || '',

                }}>{authorName}</div>
            </div>

            <Rating value={review.rating} />
            <div className='text' style={{ fontFamily: displaySettings?.textFont || '' }}>{shortenedText}</div>
            <Time time={review.time} format={dateFormat} />
        </>
    }

    return <div key={index} className={`review ${size === 1 && 'review-hover'}`}
        style={{
            animation: props.animation,
            height: `${height}px`,
        }}>
        {displaySettings?.noLinks
            ? renderContent()
            : <a key={index} href={reviewLink} className='plain h-100' target='_blank' rel='noreferrer'  >
                {renderContent()}
            </a>
        }
    </div>
}

export default ReviewBox
