import React from 'react'
import texts from '../utils/texts'
import TrialInfo from './TrialInfo';
import { MAILTO, REVIEW_LINK, SUPPORT_EMAIL } from '../utils/shared/constants';
import { Box, Button, Card, Heading, Page, Text } from '@wix/design-system';
import { Add, Reviews } from '@wix/wix-ui-icons-common';

export default function Dashboard() {
    const handleAddToSite = () => {
        (window as any).Wix.Dashboard.getEditorUrl((editorUrl: string) => {
            if (editorUrl) {
                window.open(editorUrl);
            } else {
                console.error('No editor URl');
            }
        });
    }

    const mailto = <a className='ms-1' href={MAILTO}>{SUPPORT_EMAIL}</a>;

    return (
        <Page>
            <Page.Header
                title={texts.appName}
                subtitle="Integrate your stelar reviews right into your Wix site!"
                actionsBar={
                    <Button onClick={handleAddToSite} prefixIcon={<Add />}>Add to site</Button>
                }
            />
            <Page.Content>

                <Box gap={'24px'} direction='vertical'>

                    <Card>

                        <TrialInfo location='dashboard' />

                    </Card>

                    <Card>
                        <Card.Header title='FAQ' />
                        <Card.Content>
                            <Box direction='vertical' gap='24px'>
                                <div>
                                    <Heading size="small" >How to get started?</Heading>
                                    <p>
                                        <Text>Visit our <a href='https://beaver.codes/google-reviews/how-to-add-google-reviews-to-wix/' rel="noreferrer" target='_blank'> step-by-step guide.</a></Text>
                                    </p>
                                </div>

                                <div>
                                    <Heading size="small" >Why my business can't be found?</Heading>
                                    <ul>
                                        <li>
                                            <p>
                                                <Text>If you don't have public address please check our steps for <a href='https://beaver.codes/google-reviews/how-to-add-google-reviews-to-wix/#manual' rel="noreferrer" target='_blank'>"manual" connection</a></Text>
                                            </p>

                                        </li>
                                        <li>
                                            <p>
                                                <Text>Otherwise try including <span className='bold'>country</span> or <span className='bold'>city</span> of the place in your search.
                                                </Text>
                                            </p>

                                        </li>
                                        <li>
                                            <p><Text>We will always be happy to assist at <a href={MAILTO}>{SUPPORT_EMAIL}</a></Text></p>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <Heading size="small" >How to add to site?</Heading>
                                    <p>
                                        <Text>Click the <span className='bold'>Add to site</span> button above. This will open your Wix editor with the app ready to be added. If you can't see the button please make sure you are in the <span className='bold'>site editor</span> and not the dashboard.</Text>
                                    </p>
                                </div>

                                <div>
                                    <Heading size="small" >Is the 5 reviews maximum?</Heading>
                                    <p>
                                        <Text>When you install the app we fetch from google latest reviews. Google only returns 5. We will however store those and keep adding them as we get new updates. Eventually app will show up to most recent <span className='bold'>20</span> reviews. We can also backfill older reviews on request. Please reach out to {mailto} to get that done.</Text>
                                    </p>
                                </div>

                                <Text className='mt-3 center'>Please shoot any questions or feedback to {mailto}</Text>
                            </Box>
                        </Card.Content>
                    </Card>
                    <Card>
                        <Card.Header title='App review' subtitle={<>Help us grow by leaving a review on Wix App Market</>} />
                        <Card.Content>
                            <a href={REVIEW_LINK} target='_blank' rel='noreferrer'>
                                <Button skin='standard' prefixIcon={<Reviews />} >Leave a Review</Button>
                            </a>
                        </Card.Content>
                    </Card>
                </Box>
            </Page.Content>
        </Page>
    )
}
