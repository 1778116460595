import React from 'react';
import './App.scss';
import '@wix/design-system/styles.global.css';
import SettingsDisplay from './components/SettingsDisplay';
import Display from './components/Display';
import { FirebaseAppProvider, FirestoreProvider, useFirebaseApp } from 'reactfire';
import firebaseConfig from './utils/firebaseConfig';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import Dashboard from './components/Dashboard';

function ContentApp() {
  if (window.location.pathname === '/settings') {
    return <SettingsDisplay />
  }
  if (window.location.pathname === '/dashboard') {
    return <Dashboard />
  }

  return <Display />
}

function FirebaseApp() {
  const useEmulators = process.env.REACT_APP_USE_EMULATORS || false;
  const firebaseApp = useFirebaseApp();
  const firestoreInstance = getFirestore(firebaseApp);
  if (useEmulators) {
    connectFirestoreEmulator(firestoreInstance, "localhost", 8080);
  }

  return <FirestoreProvider sdk={firestoreInstance}>
    <ContentApp />
  </FirestoreProvider>
}

function App() {
  return <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <FirebaseApp />
  </FirebaseAppProvider>
}

export default App;
