import React, { useEffect, useState } from 'react'
import LocationSearch from './LocationSearch'
import { getQueryParam } from '../utils/url'
import { useFirestore } from 'reactfire';
import { DateFormat, DisplaySettings, Settings } from '../models/shared/Settings';
import { collection, doc, onSnapshot, query } from 'firebase/firestore';
import { Collections, REVIEW_LINK } from '../utils/shared/constants';
import TrialInfo from './TrialInfo';
import { requestManualImport, updateReviews, updateSettings } from '../utils/api';
import { Button, Checkbox, FormField, IconButton, Loader, RadioGroup, Text, Tooltip } from '@wix/design-system';
import { ArrowLeft, Edit, Reviews, Update } from '@wix/wix-ui-icons-common';
import { Place } from '../models/shared/Place';
import { fromFirebaseDoc } from '../utils/shared/firebase';
import { useInstanceData } from '../hooks/useInstanceData';

const DateOptions: DateFormat[] = ['DD/MM/YYYY', 'MM/DD/YYYY', 'Hide'];
const SPEED_OPTIONS: SpeedOptions[] = [
    { value: 1, icon: 'bi-chevron-double-left' },
    { value: 0.5, icon: 'bi-chevron-left' },
    { value: -0.5, icon: 'bi-chevron-right' },
    { value: -1, icon: 'bi-chevron-double-right' },
];

const BACKFILL_TRIGGER = 5;

export default function SettingsDisplay() {
    const db = useFirestore();
    const componentId = getQueryParam('origCompId');
    const [editorSettings, setEditorSettings] = React.useState<null | DisplaySettings>(null);
    const [forceSearchLocation, setForceSearchLocation] = React.useState(false);
    const [processing, setProcessing] = React.useState(false);
    const [reviewCount, setReviewCount] = React.useState(9999);
    const [place, setPlace] = useState<Place | null>(null);
    const { onTrial, instanceData } = useInstanceData();

    const handleLocationChosen = () => {
        setForceSearchLocation(false);
    }

    const handleSettingsChange = async (newData: Partial<DisplaySettings>) => {
        setProcessing(true);
        await updateSettings({ ...editorSettings, ...newData })
        setProcessing(false);
    }



    useEffect(() => {
        if (componentId && db) {
            return onSnapshot(doc(db, Collections.settings, componentId), snapshot => {
                if (snapshot.exists()) {
                    const data = snapshot.data() as Settings;
                    setEditorSettings(data.editor);
                } else {
                    setEditorSettings({})
                }
            })
        }
    }, [componentId, db])

    useEffect(() => {
        const placeId = editorSettings?.placeId;
        if (!placeId) {
            return;
        }
        const unsubscribePlace = onSnapshot(doc(db, Collections.places, placeId), snapshot => {
            setPlace(fromFirebaseDoc<Place>(snapshot))
        });

        const reviewCol = collection(db, Collections.places, placeId, Collections.reviews);
        const unsubscribeCount = onSnapshot(query(reviewCol), snapshot => {
            setReviewCount(snapshot.size);
        })

        return () => {
            unsubscribePlace();
            unsubscribeCount();
        }
    }, [db, editorSettings?.placeId])

    if (!editorSettings) {
        return <div className='center mt-2'>
            <div className='spinner-border' />
        </div>
    }

    const handleReviewsImport = async () => {
        const instanceId = instanceData?.instanceId;
        if (!instanceId) {
            return;
        }

        setProcessing(true);
        if (editorSettings.manual) {
            await requestManualImport(editorSettings.placeUrl || '', true);
        } else {
            await updateReviews(instanceId);
        }
        setProcessing(false);
    }

    const searchLocation = forceSearchLocation || !editorSettings.placeId;
    const choosenDateFormat: DateFormat = editorSettings.dateFormat || 'DD/MM/YYYY';
    const choosenSpeed = editorSettings.speed || 1;

    if (searchLocation) {
        return <div className='settings'>
            <TrialInfo className='settings-trial' location='settings' />
            <div className='section'>
                <div className='d-flex justify-content-between'>
                    <FormField label="Business" />
                    {editorSettings.placeId && <Button size='tiny' prefixIcon={<ArrowLeft />} onClick={() => setForceSearchLocation(false)}>Back</Button>}
                </div>

                <LocationSearch onLocationChosen={handleLocationChosen} />
            </div>
        </div>
    }

    const showBackfillInfo = !editorSettings.manual && reviewCount === BACKFILL_TRIGGER;
    let LastUpdateInfoText = 'Reviews are automatically imported every 3 days';
    if (onTrial) {
        LastUpdateInfoText = 'Reviews are not automatically imported during the trial period';
    } else if (editorSettings.manual) {
        LastUpdateInfoText = 'Reviews are not automatically imported when the location is not connected through a public address';
    }

    let importTooltip = 'Import reviews for this location';
    if (editorSettings.manual) {
        importTooltip += '. This can take up to 24 hours but usually happens within few minutes';
    }

    let wasUpdatedToday = false;
    if (place?.updatedAt) {
        const today = new Date();
        wasUpdatedToday = place.updatedAt.toDateString() === today.toDateString();
    }


    return (
        <div className='settings'>
            <TrialInfo className='settings-trial' location='settings' />


            <div className='section'>
                <FormField suffix={processing && <Loader size='tiny' />} label="Business">
                    <div className='d-flex align-items-center'>
                        <p>{editorSettings.placeName}</p>
                        {editorSettings.manual && <p className='ms-1 text-muted text-primary'>(Manual)</p>}
                        <Tooltip size='small' content='Change location'>
                            <IconButton onClick={() => setForceSearchLocation(true)} size='small' skin='standard' className='ms-2'>
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip size='small' content={importTooltip}>
                            <IconButton size='small' skin='inverted' className='ms-2'
                                disabled={processing || wasUpdatedToday}
                                onClick={() => handleReviewsImport()}
                            >
                                <Update />
                            </IconButton>
                        </Tooltip>
                    </div>
                </FormField>
                {place?.updatedAt && <div className='thin'>
                    <FormField
                        label={`Last update: ${place.updatedAt.toLocaleString()}`}
                        labelSize='tiny'
                        infoContent={LastUpdateInfoText}
                    />
                </div>}
            </div>

            <hr />
            <div className='section'>
                <FormField suffix={processing && <Loader size='tiny' />} label="Minimal Star Count"
                    infoContent='Choose minimal star count to display reviews'
                >
                    <div className='mt-1'>
                        {[1, 2, 3, 4, 5].map(star => <IconButton key={star}
                            skin={editorSettings.minStarCount === star ? 'standard' : 'light'}
                            onClick={() => handleSettingsChange({ minStarCount: star })}
                            disabled={processing}
                        >
                            <>{star}</>
                        </IconButton>)}
                    </div>
                </FormField>
            </div>

            <div className='section'>
                <FormField label="Review Date" suffix={processing && <Loader size='tiny' />}
                    infoContent='Choose how or if to display each review date'
                >
                    <RadioGroup
                        value={choosenDateFormat}
                        onChange={(value) => handleSettingsChange({ dateFormat: value as DateFormat })}

                    >
                        {DateOptions.map(value => <RadioGroup.Radio key={value} value={value}>{value}</RadioGroup.Radio>)}

                    </RadioGroup>
                </FormField>
            </div>
            <div className='section'>
                <FormField suffix={processing && <Loader size='tiny' />} label="Speed"
                    infoContent='Set speed and direction of the reviews animation'
                >

                    <div className='mt-1 mx-1'>
                        {SPEED_OPTIONS.map(speedItem => <IconButton key={speedItem.value}
                            skin={choosenSpeed === speedItem.value ? 'standard' : 'light'}
                            onClick={() => handleSettingsChange({ speed: speedItem.value })}
                            disabled={processing}
                        >
                            <i className={"bi " + speedItem.icon} />
                        </IconButton>)}
                    </div>
                </FormField>
            </div>
            <div className='section'>
                <FormField suffix={processing && <Loader size='tiny' />} label="Advance Settings">
                    <div className='ms-2'>
                        <div>
                            <Checkbox
                                checked={editorSettings.textReviewsOnly || false}
                                onChange={() => handleSettingsChange({ textReviewsOnly: !editorSettings.textReviewsOnly })}
                                tooltipContent='Only show reviews with text'
                            >
                                Text Reviews Only
                            </Checkbox>
                        </div>
                        <div>

                            <Checkbox
                                onChange={() => handleSettingsChange({ randomOrder: !editorSettings.randomOrder })}
                                checked={editorSettings.randomOrder || false}
                                tooltipContent='Show reviews in random order instead of latest'
                            >
                                Random Order
                            </Checkbox>
                        </div>
                    </div>
                </FormField>
                {showBackfillInfo && <div className='section mt-3'>
                    <div className='center flex-column'>
                        <p className='text-muted text-justify mt-0'><i className='bi bi-info-circle' /> It can take up to one hour to fetch reviews for all 20 slots</p>
                    </div>
                </div>}
            </div>

            {editorSettings.placeId && <>
                <hr className='mt-2' />
                <div className='section'>
                    <FormField label="App review">
                        <p><Text weight='thin'>Help us grow by leaving a review on Wix App Market</Text></p>
                        <div>
                            <a href={REVIEW_LINK} target='_blank' rel='noreferrer'>
                                <Button prefixIcon={<Reviews />} >Leave a Review</Button>
                            </a>
                        </div>
                    </FormField>
                </div>
            </>}
        </div>
    )
}

interface SpeedOptions {
    icon: string;
    value: number;
}
