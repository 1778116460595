import React, { useEffect } from 'react'
import { SearchPlaceCandidate, requestManualImport, searchPlaces, updateSettings } from '../utils/api'
import { DisplaySettings } from '../models/shared/Settings';
import { useInstanceData } from '../hooks/useInstanceData';
import LocationSearchHelp from './LocationSearchHelp';
import { isMapsUrl, isURL } from '../utils/url';
import { ExclamationMarkSVG, HourGlassSVG, SearchSVG } from './SVG';

const MIN_CHARS_TO_SEARCH = 3

interface Props {
    onLocationChosen: () => void
}

export default function LocationSearch(props: Props) {
    const [prompt, setPrompt] = React.useState('')
    const [fetching, setFetching] = React.useState(false);
    const [processing, setProcessing] = React.useState(false);
    const [candidates, setCandidates] = React.useState<SearchPlaceCandidate[]>([]);
    const [error, setError] = React.useState<string | null>(null);
    const { instanceData, refreshInstanceData } = useInstanceData();
    const [searchCount, setSearchCount] = React.useState(0);

    useEffect(() => {
        if (prompt.length >= MIN_CHARS_TO_SEARCH && !isURL(prompt)) {
            const timeout = setTimeout(async () => {

                setError(null);
                setFetching(true)
                try {

                    const result = await searchPlaces(prompt);
                    setCandidates(result.candidates);
                    setSearchCount(oldCount => oldCount + 1);
                } catch (error) {
                    const anyError = error as any;
                    setError(anyError.message);
                }
                setFetching(false)
            }, 1000);

            return () => clearTimeout(timeout)
        }
        setFetching(false)
    }, [prompt])

    const chooseLocation = async (placeId: string, placeName: string) => {
        setProcessing(true);

        const newSettings: Partial<DisplaySettings> = {
            placeId,
            placeName,
            manual: false
        }

        try {
            await updateSettings(newSettings);
            props.onLocationChosen();
        } catch (error) {
            const anyError = error as any;
            setError(anyError.message);
        }
        setProcessing(false);
    }
    const chooseManualLocation = async () => {
        setProcessing(true);

        const newSettings: Partial<DisplaySettings> = {
            placeId: instanceData.manual?.placeId,
            placeName: instanceData.manual?.placeName,
            placeUrl: instanceData.manual?.placeUrl,
            manual: true
        }

        try {
            await updateSettings(newSettings);
            props.onLocationChosen();
        } catch (error) {
            const anyError = error as any;
            setError(anyError.message);
        }
        setProcessing(false);
    }
    const handleManualImport = async () => {
        setProcessing(true);

        try {
            console.log('manual import requested A')
            await requestManualImport(prompt);
            console.log('manual import requested')
            setPrompt('');
            refreshInstanceData();
        } catch (error) {
            const anyError = error as any;
            setError(anyError.message);
        }

        setProcessing(false);
    }


    const renderResults = () => {
        if (error) {
            return <p className='text-error'>{error}</p>
        }
        if (isMapsUrl(prompt)) {
            return <div className='mb-4'>
                <p className='mb-1'>Run one-time import of reviews from provided Google Maps profile{processing && <span className='spinner-border small ms-1' />}</p>
                <p className='text-muted'>This can take up to 24 hours and is recommended only for places without public address</p>
                <button className='btn btn-primary' disabled={processing} onClick={handleManualImport}>
                    Import reviews
                </button>
            </div>
        }
        if (isURL(prompt)) {
            return <p className='text-error'>URL based search is not supported</p>
        }

        if (fetching) {
            return <p className='text-muted'>Fetching...</p>
        }

        if (processing) {
            return <p className='text-muted'>Processing...</p>
        }

        if (!candidates?.length) {
            return <p className='text-muted'>No results</p>
        }

        return <div className='candidates'>
            {candidates.map(candidate => <button className='candidate' key={candidate.place_id}
                onClick={() => chooseLocation(candidate.place_id, candidate.name)}>
                <h4>{candidate.name}</h4>
                <p className='center-start'><img src='geo-alt.svg' alt='location' />{candidate.formatted_address}</p>
            </button>)}
        </div>
    }

    const renderManualRequestRecord = () => {
        const showManualRequest = instanceData?.manualRequest && instanceData.manualRequest.status === 'pending';
        if (!showManualRequest) {
            return null;
        }

        return <div className='border p-2'>
            <p className='my-1 d-flex align-items-center'>Import Requested {HourGlassSVG}</p>
            <p className='text-muted my-1 overflow-elipses' >Request pending for {instanceData.manualRequest?.url}</p>
        </div >
    }

    const renderManualRecord = () => {
        if (!instanceData?.manual) {
            return null;
        }
        return <div className='candidates'>
            <button className='candidate' onClick={chooseManualLocation}>
                <h4>{instanceData.manual.placeName}</h4>
                <p className='text-muted text-primary'>Manual</p>
            </button>
        </div>
    }

    const invalidSearch = isURL(prompt) && !isMapsUrl(prompt);
    return (
        <div>
            <div className={`location-search ${invalidSearch && 'invalid'}`}>
                <input type='text'
                    placeholder='Search for a business name and address'
                    value={prompt} onChange={e => setPrompt(e.target.value)} />
                {invalidSearch ? ExclamationMarkSVG : SearchSVG}
            </div>

            {renderResults()}
            {renderManualRequestRecord()}
            {renderManualRecord()}

            <LocationSearchHelp searchCount={searchCount} />
        </div>
    )
}
