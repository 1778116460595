import { MAILTO, UPGRADE_LINK } from '../utils/shared/constants';
import { useInstanceData } from '../hooks/useInstanceData';
import { Box, Button, Text } from '@wix/design-system';

interface Props {
    className?: string;
    location: 'settings' | 'dashboard';
}

export default function TrialInfo(props: Props) {
    const { instanceData, onTrial, daysLeft, trialEnded } = useInstanceData();

    const upgradeTriggered = () => {
        if (props.location === 'settings') {
            (window as any).Wix.Settings.openBillingPage();
        } else {
            window.location.href = `${UPGRADE_LINK}${instanceData.instanceId}`
        }
    }

    if (!instanceData.instanceId || !onTrial) {
        return null;
    }

    return <Box direction='vertical' gap="12px" className={`p-3 ${props.className}`}>
        {trialEnded
            ? <Text>Your trial has ended!</Text>
            : <Text>There are <Text skin='primary'>{daysLeft}</Text> days left on your trial!</Text>
        }
        <div>
            <Button skin='premium' onClick={upgradeTriggered}>Upgrade</Button>
        </div>
        <Text size='tiny'>-- <a href={MAILTO}>Share</a> your feedback for a chance of extended trial --</Text>
    </Box >
}
