import React, { useEffect } from "react";
import { InstanceData } from "../models/shared/InstanceData";
import { getRemainingTrialDays } from "../utils/logic";
import { getInstanceData } from "../utils/api";

export const useInstanceData = () => {
    const [instanceData, setInstanceData] = React.useState<Partial<InstanceData>>({});
    const [refreshFlipper, setRefreshFlipper] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);
    const onTrial = !instanceData.paidInstance;
    const daysLeft = getRemainingTrialDays(instanceData.trialEndsAt || new Date());
    const trialEnded = daysLeft < 1;

    useEffect(() => {
        getInstanceData().then(data => {
            setInstanceData(data)
            setLoaded(true)
        });
    }, [refreshFlipper])

    return {
        instanceData,
        onTrial,
        daysLeft,
        trialEnded,
        loaded,
        refreshInstanceData: () => setRefreshFlipper(!refreshFlipper)
    }
}
