import { Settings } from "http2";
import { DisplaySettings } from "../models/shared/Settings";
import { getQueryParam } from "./url";
import { InstanceData } from "../models/shared/InstanceData";
import { convertData } from "./shared/firebase";

const API_URL = process.env.REACT_APP_USE_EMULATORS
    ? 'http://localhost:5001/wix-reviews/europe-west1'
    : 'https://europe-west1-wix-reviews.cloudfunctions.net';

export interface SearchPlacesResponse {
    status: string;
    candidates: SearchPlaceCandidate[]
}

export interface SearchPlaceCandidate {
    place_id: string;
    name: string;
    formatted_address: string;
}

export const searchPlaces = async (query: string): Promise<SearchPlacesResponse> => {
    const instance = getQueryParam('instance');
    const endpoint = `${API_URL}/searchPlaces`;

    const result = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ query, instance })

    });

    return result.json();
}

export const updateSettings = async (newSettings: Partial<DisplaySettings>): Promise<Settings> => {
    const instance = getQueryParam('instance');
    const componentId = getQueryParam('origCompId');
    const endpoint = `${API_URL}/updateSettings`;

    const result = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ componentId, instance, settings: newSettings })

    });

    return result.json();
}

export const publishComponent = async (componentId: string): Promise<Settings> => {
    const instance = getQueryParam('instance');
    const endpoint = `${API_URL}/publishComponent`;

    const result = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ componentId, instance })

    });

    return result.json();
}
export const getInstanceData = async (): Promise<Partial<InstanceData>> => {
    const instance = getQueryParam('instance');
    const endpoint = `${API_URL}/getInstanceData?instance=${instance}`;

    const result = await fetch(endpoint);

    return convertData(await result.json());
}
export const requestManualImport = async (url: string, skipComponents?: boolean) => {
    const instance = getQueryParam('instance');
    const endpoint = `${API_URL}/requestManualImport`;

    await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ url, instance, skipComponents: !!skipComponents })
    });
}

export const updateReviews = async (instanceId: string) => {
    const endpoint = `${API_URL}/updateReviews?instanceId=${instanceId}`;

    await fetch(endpoint);
}
